import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {Message, Modal, Breadcrumb, Card, Grid, Form, Space, Button, Input, Select, Switch, Table} from "@arco-design/web-react";
import {IconRefresh, IconClose, IconCloseCircle, IconCheckCircle, IconPlusCircle, IconDelete} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import File from "../../../../../nowui-common/component/file";
import Loading from "../../../../../nowui-common/component/loading";

const CheckDetail = ({...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState({});
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [companyUserList, setCompanyUserList] = useState([]);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [checkItemList, setCheckItemList] = useState([]);

    useEffect(() => {
        let breadcrumb = {};
        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/check/index");
        if (props.action == "save") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.new"}) + outletContext.intl.formatMessage({id: "check"}).toLowerCase(),
            };
        } else if (props.action == "update") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.modify"}) + outletContext.intl.formatMessage({id: "check"}).toLowerCase(),
            };

            handleLoadCheck();

            setIsRefresh(true);
        }
        setBreadcrumb(breadcrumb);
        setBreadcrumbList(breadcrumbList.concat([breadcrumb]));

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        return () => {};
    }, [localStorage.getItem("locale")]);

    const handleLoadCompanyUserList = (companyId) => {
        Util.request(outletContext, {
            url: process.env.API_URL + "/company/user/frontend/website/v1/list/select",
            controller: controller,
            data: {
                companyId: companyId,
            },
            success: (response) => {
                setCompanyUserList(response.data);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);
            },
            complete: () => {},
        });
    };

    const handleLoadCheck = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/check/frontend/website/v1/item",
            controller: controller,
            data: {
                checkId: props.checkId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                setCheckItemList(response.data.checkItemList);

                handleLoadCompanyUserList(response.data.companyId);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleSubmitCheck = () => {
        if (props.action == "save") {
            handleSaveCheck();
        } else if (props.action == "update") {
            handleUpdateCheck();
        }
    };

    const handleSaveCheck = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                Util.request(outletContext, {
                    url: process.env.API_URL + "/check/frontend/website/v1/save",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    const handleUpdateCheck = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.checkId = props.checkId;
                data.checkItemList = checkItemList;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/check/frontend/website/v1/update",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}
                title={
                    <React.Fragment>
                        <div className="arco-modal-title-text">{breadcrumb.name}</div>
                        <Space>
                            <Button
                                className="arco-modal-title-button"
                                type="text"
                                icon={<IconRefresh className="navigation_icon" />}
                                onClick={() => {
                                    handleLoadCheck();
                                }}
                            ></Button>
                            <Button
                                className="arco-modal-title-button"
                                type="text"
                                icon={<IconClose className="navigation_icon" />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            ></Button>
                        </Space>
                    </React.Fragment>
                }
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                closeIcon={false}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, marginRight: "8px"}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                            {props.action === "save" ? (
                                <Button
                                    type="primary"
                                    icon={<IconCheckCircle />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitCheck();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.save"})}
                                </Button>
                            ) : null}
                            {props.action === "update" ? (
                                <Button
                                    type="primary"
                                    icon={<IconCheckCircle />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitCheck();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.update"})}
                                </Button>
                            ) : null}
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        style.overflow = isLoad ? "hidden" : "auto";

                        return <div className="scrollbar" style={style} {...props} />;
                    }}
                >
                    <div style={{width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}>
                        <Breadcrumb className="breadcrumb">
                            {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                                return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                            })}
                        </Breadcrumb>
                        <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                        <Form form={form} autoComplete="off" layout="vertical">
                            <Card className="card" title={outletContext.intl.formatMessage({id: "global.basicInformation"})} bordered={false}>
                                <Grid.Row gutter={20}>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "check.checkName"})}
                                            field="checkName"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitCheck();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "check.checkLocation"})}
                                            field="checkLocation"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitCheck();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    {props.action == "update" ? (
                                        <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                            <Form.Item
                                                label={outletContext.intl.formatMessage({id: "check.checkUser"})}
                                                field="userIdList"
                                                rules={[]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    placeholder={outletContext.intl.formatMessage({id: "global.pleaseSelect"})}
                                                    allowClear
                                                    onChange={(event) => {}}
                                                >
                                                    {companyUserList.map((companyUser, companyUserIndex) => {
                                                        return (
                                                            <Select.Option key={companyUserIndex} value={companyUser.userId}>
                                                                {companyUser.userName}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Grid.Col>
                                    ) : null}
                                </Grid.Row>
                            </Card>
                            {props.action == "update" ? (
                                <Card className="card" title={outletContext.intl.formatMessage({id: "check.checkItem"})} bordered={false}>
                                    <div className="card_table_action">
                                        <Space className="action_detail" wrap>
                                            <Button
                                                icon={<IconPlusCircle />}
                                                onClick={() => {
                                                    checkItemList.push({
                                                        itemId: Util.getId(),
                                                        itemName: null,
                                                        itemImage: null,
                                                    });
                                                    setCheckItemList([...checkItemList]);
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.save"})}
                                            </Button>
                                            <Button
                                                icon={<IconDelete />}
                                                disabled={selectedIdList.length == 0}
                                                onClick={() => {
                                                    for (let i = 0; i < selectedIdList.length; i++) {
                                                        for (let j = 0; j < checkItemList.length; j++) {
                                                            if (selectedIdList[i] == checkItemList[j].id) {
                                                                checkItemList.splice(j, 1);
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    setCheckItemList([...checkItemList]);
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.delete"})}
                                            </Button>
                                        </Space>
                                        <Table
                                            className="table"
                                            columns={[
                                                {
                                                    dataIndex: "itemName",
                                                    title: outletContext.intl.formatMessage({id: "check.itemName"}),
                                                    render: (col, record, index) => (
                                                        <React.Fragment>
                                                            <Input
                                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                allowClear
                                                                value={record.itemName}
                                                                onChange={(event) => {
                                                                    checkItemList[index].itemName = event;
                                                                    setCheckItemList([...checkItemList]);
                                                                }}
                                                            />
                                                        </React.Fragment>
                                                    ),
                                                },
                                                {
                                                    dataIndex: "itemImage",
                                                    title: outletContext.intl.formatMessage({id: "check.itemImage"}),
                                                    render: (col, record, index) => (
                                                        <React.Fragment>
                                                            <File
                                                                type="image"
                                                                limit={1}
                                                                value={record.itemImage}
                                                                onChange={(event) => {
                                                                    checkItemList[index].itemImage = event;
                                                                    setCheckItemList([...checkItemList]);
                                                                }}
                                                            />
                                                        </React.Fragment>
                                                    ),
                                                },
                                            ]}
                                            rowKey="itemId"
                                            data={checkItemList}
                                            childrenColumnName="childrenList"
                                            rowSelection={{
                                                type: "checkbox",
                                                columnWidth: 50,
                                                selectedRowKeys: selectedIdList,
                                                onChange: (selectedRowKeys) => {
                                                    setSelectedIdList(
                                                        Util.getSelectedIdList(selectedIdList, checkItemList, "itemId", selectedRowKeys)
                                                    );
                                                },
                                            }}
                                            border={false}
                                            pagination={false}
                                        />
                                    </div>
                                </Card>
                            ) : null}
                        </Form>
                        <div className="card_bottom"></div>
                    </div>
                </Scrollbars>
                {isLoad ? <Loading /> : null}
            </Modal>
        </React.Fragment>
    );
};

CheckDetail.propTypes = {
    visible: PropTypes.bool.isRequired,
    action: PropTypes.string.isRequired,
    checkId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Util.withRouter(CheckDetail);
