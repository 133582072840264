import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    Message,
    Modal,
    Breadcrumb,
    Card,
    Grid,
    Form,
    Space,
    Button,
    Divider,
    Table,
    Pagination,
    Input,
    Dropdown,
    Menu,
    Select,
    Image,
    Tag,
} from "@arco-design/web-react";
import {IconSearch, IconUndo, IconPlusCircle, IconEye, IconDelete, IconUpload, IconDownload} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Import from "../../../../../nowui-common/component/import";
import Process from "../../../../../nowui-common/component/process";
import Loading from "../../../../../nowui-common/component/loading";
import Selected from "../../../../../nowui-common/component/selected";
import Sort from "../../../../../nowui-common/component/sort";

import CheckDetail from "./detail";

const CheckIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const columnListRef = useRef([]);
    const [columnList, setColumnList] = useState([]);
    const [operationColumn, setOperationColumn] = useState({});
    const [isLoad, setIsLoad] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [processType, setProcessTyp] = useState(0);
    const [isImport, setIsImport] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [action, setAction] = useState("");
    const [checkId, setCheckId] = useState("");
    const [checkCount, setCheckCount] = useState(0);
    const [checkList, setCheckList] = useState([]);
    const [checkPageIndex, setCheckPageIndex] = useState(1);
    const [checkPageSize, setCheckPageSize] = useState(10);
    const [sortList, setSortList] = useState([]);

    useEffect(() => {
        handleSearchCheck();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/check/index");
        setBreadcrumbList(breadcrumbList);

        let columnList = [
            {
                dataIndex: "checkName",
                title: outletContext.intl.formatMessage({id: "check.checkName"}),
            },
            {
                dataIndex: "checkLocation",
                title: outletContext.intl.formatMessage({id: "check.checkLocation"}),
            },
            {
                dataIndex: "checkUser",
                title: outletContext.intl.formatMessage({id: "check.checkUser"}),
                render: (col, record, index) => (
                    <React.Fragment>
                        {record.userNameList.map((userName, userNameIndex) => {
                            return (
                                <div key={userNameIndex}>
                                    {userNameIndex > 0 ? "," : null}
                                    {userName}
                                </div>
                            );
                        })}
                    </React.Fragment>
                ),
            },
            {
                dataIndex: "createTime",
                title: outletContext.intl.formatMessage({id: "global.createTime"}),
            },
        ];
        setColumnList(columnList);

        setOperationColumn({
            width: 110,
            fixed: "right",
            dataIndex: "operation",
            title: outletContext.intl.formatMessage({id: "global.operation"}),
            render: (col, record, index) => (
                <React.Fragment>
                    <Button
                        type="default"
                        icon={<IconEye />}
                        onClick={() => {
                            setIsDetail(true);
                            setAction("update");
                            setCheckId(record.checkId);
                        }}
                    >
                        {outletContext.intl.formatMessage({id: "global.view"})}
                    </Button>
                </React.Fragment>
            ),
        });

        Util.changeSortList(sortList, setSortList, columnList);
    }, [localStorage.getItem("locale")]);

    useEffect(() => {
        onSelect(selectedList);
    }, [selectedList]);

    useEffect(() => {
        columnListRef.current = columnList;
    }, [columnList]);

    useEffect(() => {
        let columnList = columnListRef.current;
        if (columnList.length == 0) {
            return;
        }
        for (let i = 0; i < columnList.length; i++) {
            let direction = "";
            for (let j = 0; j < sortList.length; j++) {
                if (columnList[i].dataIndex == sortList[j].field) {
                    direction = sortList[j].direction;
                    break;
                }
            }
            columnList[i].sortOrder = direction;
        }
        setColumnList([...columnList]);

        handleSearchCheck();
    }, [sortList]);

    const handleSearchCheck = () => {
        handleLoadCheckList(1, checkPageSize);
    };

    const handleLoadCheckList = (checkPageIndex, checkPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;
        data.pageIndex = checkPageIndex;
        data.pageSize = checkPageSize;

        Util.request(outletContext, {
            url: process.env.API_URL + "/check/frontend/website/v1/list",
            controller: controller,
            data: data,
            success: (response) => {
                setCheckCount(response.count);
                setCheckList(response.data);
                setCheckPageIndex(checkPageIndex);
                setCheckPageSize(checkPageSize);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleDeleteCheck = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: outletContext.intl.formatMessage({id: "global.deleteConfirmTitle"}),
            content: outletContext.intl.formatMessage({id: "global.deleteConfirmContent"}),
            okButtonProps: {
                status: "danger",
            },
            onOk: () => {
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                Util.request(outletContext, {
                    url: process.env.API_URL + "/check/frontend/website/v1/delete",
                    controller: controller,
                    data: {
                        checkIdList: selectedIdList,
                    },
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        setSelectedIdList([]);
                        setSelectedList([]);

                        handleLoadCheckList(checkPageIndex, checkPageSize);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            },
        });
    };

    return (
        <React.Fragment>
            <Scrollbars
                autoHeight={autoHeight}
                autoHeightMax={main.height - 300}
                renderView={({style, ...props}) => {
                    return <div className="scrollbar" style={style} {...props} />;
                }}
            >
                <Breadcrumb className="breadcrumb">
                    {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                        return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                    })}
                    {props.breadcrumb ? <Breadcrumb.Item key={props.breadcrumb.name}>{props.breadcrumb.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
                <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                <Form form={form} autoComplete="off" layout="vertical">
                    <Card className="card" title={outletContext.intl.formatMessage({id: "global.searchTable"})} bordered={false}>
                        <Grid.Row gutter={20}>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "check.checkName"})} field="checkName">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchCheck();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "check.checkLocation"})} field="checkLocation">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchCheck();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                        </Grid.Row>
                        <Space className="action" wrap>
                            <Button
                                type="primary"
                                icon={<IconSearch />}
                                loading={isLoad}
                                onClick={() => {
                                    handleSearchCheck();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.search"})}
                            </Button>
                            <Button
                                icon={<IconUndo />}
                                loading={isLoad}
                                onClick={() => {
                                    form.resetFields();

                                    setSelectedIdList([]);
                                    setSelectedList([]);
                                    setSortList([]);
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.reset"})}
                            </Button>
                            <Divider type="vertical" />
                            <Button
                                icon={<IconPlusCircle />}
                                onClick={() => {
                                    setIsDetail(true);
                                    setAction("save");
                                    setCheckId("");
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.save"})}
                            </Button>
                            <Button
                                icon={<IconDelete />}
                                disabled={selectedIdList.length == 0}
                                onClick={() => {
                                    handleDeleteCheck();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.delete"})}
                            </Button>
                        </Space>
                        <Sort sortList={sortList} setSortList={setSortList} />
                        <Table
                            className="table"
                            columns={columnList.concat([operationColumn])}
                            rowKey="checkId"
                            data={checkList}
                            rowSelection={{
                                type: selectType,
                                columnWidth: 50,
                                selectedRowKeys: selectedIdList,
                                onChange: (selectedRowKeys) => {
                                    setSelectedIdList(Util.getSelectedIdList(selectedIdList, checkList, "checkId", selectedRowKeys));
                                    setSelectedList(Util.getSelectedList(selectedList, checkList, "checkId", selectedRowKeys));
                                },
                            }}
                            scroll={{x: 50 + columnList.length * 150 + operationColumn.width}}
                            border={false}
                            pagination={false}
                            onChange={(pagination, sorter, filters) => {
                                setSortList(Util.getSortList(sortList, sorter, columnList));
                            }}
                        />
                        <div className="pagination">
                            <div className="pagination_selected">
                                <Button
                                    onClick={() => {
                                        setIsSelected(true);
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.selected"})} {selectedIdList.length}
                                </Button>
                            </div>
                            {typeof checkCount == "undefined" ? null : (
                                <Pagination
                                    current={checkPageIndex}
                                    total={checkCount}
                                    simple={main.isMobile}
                                    showTotal
                                    sizeCanChange={!main.isMobile}
                                    onChange={(pageIndex, pageSize) => {
                                        handleLoadCheckList(pageIndex, pageSize);
                                    }}
                                />
                            )}
                        </div>
                    </Card>
                </Form>
                <div className="card_bottom"></div>
            </Scrollbars>
            {isLoad ? <Loading /> : null}
            {isDetail ? (
                <CheckDetail
                    visible={isDetail}
                    action={action}
                    checkId={checkId}
                    onClose={() => {
                        setIsDetail(false);
                    }}
                    onSubmit={() => {
                        setIsDetail(false);

                        handleLoadCheckList(checkPageIndex, checkPageSize);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

CheckIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(CheckIndex);
